// UNSPLASH API TYPES
export const FETCH_API_START = 'FETCH_API_START';
export const FETCH_API_SUCCESS = 'FETCH_API_SUCCESS';
export const FETCH_API_FAILURE = 'FETCH_API_FAILURE';

// SEARCH TYPES
export const SEARCH_API_START = 'SEARCH_API_START';
export const SEARCH_API_SUCCESS = 'SEARCH_API_SUCCESS';
export const SEARCH_API_FAILURE = 'SEARCH_API_FAILURE';
export const RESET_SEARCH = 'RESET_SEARCH';
